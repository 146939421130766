import { useIndex } from '../Factories/useIndex';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Announcement,
  AnnouncementCreateInput,
  AnnouncementFilters,
  AnnouncementIncludes,
  AnnouncementSorts,
  AnnouncementUpdateInput,
} from '../Types/Resources/Announcement';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IAnnouncementProps
  extends HookParams<
    typeof AnnouncementIncludes,
    AnnouncementFilters,
    typeof AnnouncementSorts
  > {
  announcement?: UUID;
}

export function useAnnouncement(
  { announcement, ...queryParameters }: IAnnouncementProps = {},
  settings?: IUseQueryOptions<DataDocument<Announcement>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { announcement: defaultAnnouncement },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(announcement || defaultAnnouncement),
      baseName: 'v1.announcements',
      baseUri: '/v1/announcements',
      invalidate: ['announcements', 'v1.announcements'],
      uri: `/${announcement}`,
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    Announcement,
    AnnouncementCreateInput,
    AnnouncementUpdateInput
  >(request, settings);
}

export function useAnnouncements(
  { ...queryParameters }: Omit<IAnnouncementProps, 'announcement'> = {},
  settings?: IUseQueryOptions<DataDocument<Announcement[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.announcements',
      invalidate: ['announcements', 'v1.announcements'],
      baseUri: '/v1/announcements',
      queryParameters,
    },
    context
  );

  return useIndex<Announcement>(request, settings);
}
